
.header-modal-container
  padding: 24px 16px 8px

.header-modal
  display: flex
  justify-content: center
  width: 100%

.body-modal
  padding: 16px

.header-modal-title
  font-size: 18px
  font-weight: bold
  font-family: $font-family-base !important
  align-self: center


.header-modal-close
  cursor: pointer
  color: $color-bnn-gray-light
  align-self: center

.empty-data
	text-align: center
	color: $color-bnn-gray-medium-2
	padding: 48px 0

.link
  width: fit-content
  display: inline
  color: $color-bnn
  text-decoration: underline
  cursor: pointer
  &.disabled
    color: $color-bnn-gray-light
    text-decoration: none
    cursor: not-allowed

.input-container {
	.input-otp-container {
		display: flex;
		flex-wrap: nowrap;
		gap: 12px;
		justify-content: center;
		margin-bottom: 16px;

		&.is-invalid {
			.input-otp {
				box-shadow: 0 0 0 1px $color-red;
			}
		}

		.input-otp {
			width: 100%;
			height: 68px;
			font-size: 24px;
			font-weight: bold;
			text-align: center;
			border: none;
			box-shadow: 0 0 0 1px $color-bnn-gray-light;
			border-radius: 4px;

			&:hover {
				box-shadow: 0 0 0 1px $color-bnn-border;
			}

			&:focus,
			&:focus-visible {
				box-shadow: 0 0 0 2px $color-bnn-border;
				outline: none;
			}

			/* Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type=number] {
				-moz-appearance: textfield;
			}
		}
	}
}

.divider-with-text {
	display: flex;
	align-items: center;
	text-align: center;
	color: black;
	font-size: 14px;

	&::before,
	&::after {
	  content: '';
	  flex: 1;
	  border-bottom: 1px solid #dedede;
	}
	&:not(:empty)::before {
	  margin-right: 16px;
	}
	&:not(:empty)::after {
	  margin-left: 16px;
	}
}

.btn-scan-qr-code {
  font-weight: bold;
  color: $color-dark-gray-medium;

  .v-icon {
	color: $color-bnn-border
  }
}

.desc-seven-club-member {
	text-align: center;
	font-size: 14px;
	color: $color-dark-gray-medium;
}

.error-message {
	color: $color-red;
	font-size: 12px;
	text-align: center;
	margin-bottom: 8px;
}

.member-container:not(.is-pre-register) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
}

.member-container.is-pre-register {
    width: 100%
    display: flex
    flex-direction: column
    gap: 14px
    justify-content: center
    > div:first-child {
        width: 100%
        flex-shrink: 0

        @media screen and (min-width: 768px) {
            max-width: 320px
        }
    }

    @media screen and (min-width: 768px) {
        flex-direction: row
        flex-wrap: wrap

        > div:first-child {
            width: 100%
            max-width: calc(100% - 180px)
            flex-shrink: 0

            @media screen and (min-width: 768px) {
                max-width: 320px
            }
        }
    }
}

.seven-club-member-studio7 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.title-seven-club-member{
    display inline-flex
    justify-content center
    align-items center
    padding-bottom 16px
}
